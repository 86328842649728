import React from 'react';
import './App.scss';
import {Route} from 'react-router-dom';
import {Routes} from 'react-router';
import {ROUTES} from 'routes';
import HomePage from '../../pages/HomePage/HomePage';
import LoginPage from '../../pages/LoginPage/LoginPage';

import 'react-dropdown/style.css';

const App = (): JSX.Element => {

    return (
        <div>
            <Routes>
                <Route path={ROUTES.LOGIN} element={<LoginPage/>}/>
                <Route path={ROUTES.HOME} element={<HomePage/>}/>

            </Routes>
        </div>
    );

}
export default App;